<template>
  <div 
    :class="'report_box_item report_format ' + (data.is_video ? ' enable_video ' : '') + ' stage_is_' + data.max_faza + ' view_all'"  
    v-if="data"
       
    >
       
   <NuxtLink class="cover" :to="props.data.link">

      <picture>
        <source :srcset="data.avatar_small?.replace('.jpg', '.webp') + ' 1x, ' + data.avatar_small?.replace('.jpg', '.webp') + ' 2x'" type="image/webp">
        <source :srcset="data.avatar_small + ' 1x, ' + data.avatar_small + ' 2x'" type="image/jpeg"> 
        <img loading="lazy" @click="onDiaryLinkClick" :src="data.avatar_small?.replace('.jpg', '.webp')" 
          :srcset="data.avatar_small?.replace('.jpg', '.webp') + ' 1x, ' + data.avatar_small?.replace('.jpg', '.webp') + ' 2x'"          
          class="img"           
          :alt="data.name"
          >
      </picture>
      
      <div class="bx">
        {{ data.cnt_days }} weeks
        <i v-if="data.is_video" class="icon-play"></i>          
        <i v-if="data.is_question" class="icon-feed-problem"></i>          
      </div>

    </NuxtLink>

   


    <NuxtLink :to="props.data.link" class="name" :title="data.name">
      <span @click="onDiaryLinkClick">{{ data.name }}</span>
    </NuxtLink>


  
    <NuxtLink 
      v-if="data?.item_user?.link"
      :to="data.item_user.link" 
      class="user" 
      :title="data.item_user.name"
      >
      {{ data.item_user.name }}
    </NuxtLink>

    <div v-if="data?.items_seed?.length == 1" class="strains" :title="data.items_seed[0]?.item_brand?.name + ' - ' + data.items_seed[0]?.name">                   

      <img loading="lazy" 
        v-if="data.items_seed[0]?.item_brand?.logo_s"
        :src="data.items_seed[0]?.item_brand?.logo_s?.replace('.png', '.webp')?.replace('.jpg', '.webp')" 
        :class="'logo ' + (data.items_seed[0]?.item_brand?.id == 1 ? 'is_custom' : '')"
        :title="data.items_seed[0]?.item_brand?.name + ' - ' + data.items_seed[0].name">

      <span v-if="data.items_seed[0]?.item_brand?.id == 1 || !data.items_seed[0]?.item_brand?.id">{{ data.items_seed[0].name }}</span>
      <span v-else="">{{ data.items_seed[0]?.name }}</span>
    </div>

    <div v-else-if="data?.items_seed" class="strains">                   
        <img loading="lazy" 
          v-for="(opt,ind) in brandSeedUniqLogos"
          :key="ind"  
          :src="opt.item_brand?.logo_s?.replace('.png', '.webp')?.replace('.jpg', '.webp')" 
          :class="'logo ' + (opt.item_brand?.id == 1 ? 'is_custom' : '')"
          :title="opt.item_brand?.name + ' - ' + opt.name">         
        <template v-if="data.items_seed.length - brandSeedUniqLogos.length > 0">
          +{{ data.items_seed.length - brandSeedUniqLogos.length }} {{ $plurals.get('strain', data.items_seed.length - brandSeedUniqLogos.length) }}
        </template>
        <template v-else>
          {{ data.items_seed.length }} {{ $plurals.get('strain', data.items_seed.length) }}
        </template>
    </div>
      
    

    <div class="stat">   
      {{ $dayjs(data.add_last_day).utc().fromNow() }}  
      <template v-if="data.cnt_comments">
        · 
        {{ data.cnt_comments }}
        {{ $plurals.get('comment', data.cnt_comments) }}
      </template>
    </div>


   
 

  </div>
</template>


<script setup lang="ts">

import type { DiaryShort, DiaryEquip } from '@/types/diary'

interface PageProps {
  data: DiaryShort;
}

const props = defineProps<PageProps>();

const uniqBy = (arr: DiaryEquip[], key: string) => {
  return [...new Map(arr.map((item: DiaryEquip) => [item.item_brand?.id, item])).values()]
}

const brandSeedUniqLogos = computed(() => {
  return uniqBy(props.data.items_seed, 'item_brand.id').slice(0, 2);
});




const onDiaryLinkClick = (e: MouseEvent) => {

  console.log('onDiaryLinkClick', e);

  if (window.outerWidth > 768) {
    
    if (e.metaKey || e.ctrlKey || e.shiftKey || e.altKey || e.button === 1) {
      return;
    }
    
    if(useExperimentsDiaryWide().value) return false;

    console.log('continue diary open');

    e.preventDefault()
    e.stopPropagation()

    // useModalsDiary().value = null;
    // useModalsDiaryShow().value = false

    useModalsDiary().value = {
      id: props.data.id, 
      link: props.data.link,
      data: props.data
    }
    useModalsDiaryShow().value = true
  }
 
}


// onMounted(() => {
//   if(useExperimentsDiaryWide().value){
//     if(import.meta.client){
//       if(window.outerWidth > 768){

//         // diaryLink.value = null;
//       }
//     }
//   }
// })


</script>
   

<style scoped>
  
 


.report_box_item {
  width: 180px;
  min-width: 180px;
  display: inline-block;
  vertical-align: top;
  margin-bottom: 15px;
  text-align: left;
  margin-right: 13px;
  aspect-ratio: 180/210;
  flex-shrink: 0;
  contain: layout paint size;
}

.r_sidebar .report_box_item{
  margin-bottom: 25px;
}

.report_box_item .cover{
  opacity: 0.5;
}

.report_box_item:hover  .cover,
.report_box_item.is_new .cover,
.report_box_item.is_update .cover,
.report_box_item.view_all .cover {
  opacity: 1;
}

.report_box_item .is_new_diary{
    display: inline-block;
    border:1px var(--un-primary-back-color) solid;
    color: var(--un-primary-back-color);
    border-radius: 3px;
    font-size: 9px;
    padding: 2px 6px;
    margin-right: 5px;
    vertical-align: middle;
}
.report_box_item .is_upd_diary{
        display: inline-block;
    border:1px #ff4500 solid;
    color: #ff4500;
    border-radius: 3px;
    font-size: 9px;
    padding: 2px 6px;
    margin-right: 5px;
    vertical-align: middle;
}


.report_box_item.is_update .name::before {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--un-primary-back-color);
    vertical-align: middle;
    display: inline-block;
    margin-right: 2px;
}

.report_box_item .cover{
    position: relative;
    display: block;
}
.report_box_item .cover{
    width: 100%;
    /* height: 122px; */
    aspect-ratio: 180/122;
    overflow: hidden;
    border-radius: 3px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.09);
    background-color: var(--gd-diary-cover-color);
}
.report_box_item .cover picture{
  width: 100%;
  height: 100%;
}
.report_box_item .cover .img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.report_box_item .cover .weeks .enable_video{
    margin-left: 5px;
}
.report_box_item .cover .weeks{
    color: white;
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0px;
}
 

.report_box_item .premium_label{
  color: red;
  background-color: #e8e8e8;
  display: inline-block;
  font-size: 0.85rem;
  padding: 3px 9px;
  font-weight: bold;
  border-radius: 3px;
  height: 23px;
  align-items: center;
  margin-right: auto;
  margin-left: 0;
}
.report_box_item .premium_label::before{
  content: url(/images/icon_premium_crown.svg);
  width: 13px;
  height: 13px;
  display: inline-block;
  margin-right: 5px;
  vertical-align: text-top;
}
.report_box_item .name{
    padding: 7px 9px 0px 0px;
    
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: bold;
    display: block;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.report_box_item .user{
    color: var(--gd-diary-sub-color);
    opacity: 0.8;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.report_box_item .user:hover{
    color: #4183C4;
}
.report_box_item .user .avatar{}
.report_box_item .user .user_range{}
.report_box_item .user .info{}
.report_box_item .user .info .name{}
.report_box_item .user .info .status{}
.report_box_item .strains{
  color: var(--gd-diary-sub-color);
  opacity: 0.8;
  font-size: 0.8rem;
  display: -webkit-flex;
  -webkit-align-items: center;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  gap: 3px;
}
.report_box_item .strains span{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
.report_box_item .strains picture{
  flex-shrink: 0;
}
.report_box_item .strains .logo{
  width: 26px;
  vertical-align: bottom;
  flex-shrink: 0;
  aspect-ratio: 1/1;
  object-fit: contain;
} 
.report_box_item .strains .logo.is_custom{
  /* padding: 4px 0; */
}
.report_box_item .stat{
    color: var(--gd-diary-date-color);
    font-size: 0.7rem;
    white-space: nowrap;
    overflow: hidden;
    display: -webkit-flex;
    -webkit-align-items: center;
    display: flex;
    opacity: 0.85;
    align-items: center;
}   
 

.report_box_item.stage_is_-1 .cover{
   border-bottom: 2px  var(--gd-faza-ger) solid;
}

.report_box_item.stage_is_0 .cover{
   border-bottom: 2px  var(--gd-faza-veg) solid;
}

.report_box_item.stage_is_1 .cover{
    border-bottom: 2px  var(--gd-faza-flo) solid;
}

.report_box_item.stage_is_2 .cover{
    border-bottom: 2px var(--gd-faza-har) solid;
}
.report_box_item .bx{
    display: inline-block;
    font-size: 0.7rem;
    padding: 2px 9px;
    border-radius: 3px 3px 0px 0px;
    position: absolute;
    bottom: -1px;
    right: 4px;
    color: white;
    align-content: center;
    pointer-events: none;
}

 .report_box_item .bx i{
   margin-left: 4px;
  font-size: 0.6rem;
  height: 11px;
 }

.report_box_item.stage_is_-1 .bx{
    background: var(--gd-faza-ger) ;
}

.report_box_item.stage_is_0 .bx{
  background: var(--gd-faza-veg); /* Old browsers */ 
}

.report_box_item.stage_is_1 .bx{
  background: var(--gd-faza-flo); /* Old browsers */ 
}

.report_box_item.stage_is_2 .bx{
    background: var(--gd-faza-har); /* Old browsers */ 
}
/* DEACTIVE */
.report_box_item.deactive {
  opacity: 0.7;
}
.report_box_item.deactive .bx {
      background-color: #dadada;
}
.report_box_item.deactive {
    border-bottom: 2px #dadada solid;
}
.report_box_item.deactive .cover .img {
    filter: url("data:image/svg+xml;utf8,&lt;svg xmlns=\'http://www.w3.org/2000/svg\'&gt;&lt;filter id=\'grayscale\'&gt;&lt;feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale"); /* Firefox 10+, Firefox on Android */
    filter: gray; /* IE6-9 */
    -webkit-filter: grayscale(100%); /* Chrome 19+, Safari 6+, Safari 6+ iOS */
}

/**/

@container pb (max-width: 648px) {
  .report_box_item{
    width: calc(33.3333% - 14px);
    min-width: calc(33.3333% - 14px);
  } 
}

@container pb (max-width: 500px) {
  .report_box_item{
    width: calc(50% - 20px);
    min-width: calc(50% - 20px);
  } 
} 

/* position_left */

.position_left{

}

.position_left .report_box_item{
  width: 100%;
  min-width: 100%;
    display: flex;
    margin-right: 0px;
    margin-bottom: 25px;
}

.position_left .report_box_item .cover{
  width: 140px;
}
/* 
.position_left .report_box_item .cover{

    height: 94px;
} */


/* scrolled horizontal */
/* 

.horizontal .report_box_item{
  width: 170px;
  min-width: 170px;
}  */


/* @container pb (max-width: 668px) {
  .horizontal .report_box_item{
    width: calc(100vw/3 - 3rem);
    min-width: 160px;
    flex-shrink: 0;
  } 
} */

@container pb (max-width: 500px) {
  .horizontal_all .report_box_item,
  .horizontal_mobile .report_box_item{
    width: 170px;
    min-width: 160px;
    flex-shrink: 0;
  } 
}
/*  */

</style>
